import React, { lazy } from "react";
import { Navigate, RouteObject, useRoutes } from "react-router-dom";
import storage from "../helpers/storage";
import protectRoutes from "./protected";
import LoginComponent from "../pages/login";
import { RootState } from "../store";
import { useSelector } from "react-redux";
import { ALWAYS_ALLOWED_PROTECTED_ROUTE, DASHBOARD_PATH, MODULES_WITH_PATH, ROLES } from "../constants";
import { getDecodedToken } from "../helpers";
import { User } from "../store/reducers/auth";


const ForgotPasswordComponent = lazy(() =>
    import('../pages/forgot-password')
);
const ResetPasswordComponent = lazy(() =>
    import('../pages/reset-password')
);
const SetUpPasswordComponent = lazy(() =>
    import('../pages/setup-password')
);
const CustomizedFair = lazy(() =>
    import('../pages/customized-fair')
);
const PageNoAccessComponent = lazy(() =>
    import('../pages/no-access-page')
);

const AppRoutes: React.FC = () => {
    const { loading, permission } = useSelector((state: RootState) => state.auth);

    const router: RouteObject[] = [
        {
            path: '/',
            element: <Navigate to={"/login"}/>
        },
        {
            path: 'login',
            element: <LoginComponent />
        },
        {
            path: 'forgot-password',
            element: <ForgotPasswordComponent />
        },
        {
            path: 'reset-password',
            element: <ResetPasswordComponent />
        },
        {
            path: 'setup-password',
            element: <SetUpPasswordComponent />
        },
        {
            path: 'customized-fair/:school_identifier/:fair_identifier',
            element: <CustomizedFair title="Customized Fair"/>
        },
    ];

    const _pRoutes = protectRoutes.map((i: RouteObject) => {
        return {...i}
    });

    const user: User | null = getDecodedToken();
    
    /**
     * When the user is not logged in and access an unknown page.
     */
    if(user === null) {
        router.push({
            path: '*',
            element: <PageNoAccessComponent isPublic={true}/>
        })
    }

    const getAbsolutePath = (child: RouteObject) => {
        return (child.path as string).replace("/:id", "")
    }
    
    /**
     * Check and set routes as per permissions
     */
    if(permission.length && user?.role?.role && user?.role.role !== ROLES.SUPER_ADMIN) {

        const allowedPaths: string[] = []
        
        for (const route of _pRoutes) {
            for (const child of route.children ?? []) {
                const childPath = getAbsolutePath(child);

                // get module from route
                const module = MODULES_WITH_PATH.find((m: any) => m.read_only.includes(childPath) || m.edit.includes(childPath))
                if(module) {
                    // get permission from module menuName
                    const p = permission.find((p: any) => p.name === module.menuName);

                    // check for read_only and edit
                    const read_only = p?.read_only && module.read_only.includes(childPath);
                    const edit = p?.edit  && module.edit.includes(childPath);
                    
                    if((read_only || edit) && !allowedPaths.includes(childPath)) {
                        allowedPaths.push(childPath);
                    }

                } else if(ALWAYS_ALLOWED_PROTECTED_ROUTE.includes(childPath) && !allowedPaths.includes(childPath)) {
                    // set allowed paths
                    allowedPaths.push(childPath);
                }
                
            }
            route.children = (route.children || []).filter((a: any) => allowedPaths.includes(getAbsolutePath(a)));
            break;
        }
    }

    const mainRoutes = storage.getToken() !== null ? [..._pRoutes] : [];
    const routes = useRoutes([...router, ...mainRoutes]);
    return (<React.Fragment>{loading ? '' : routes}</React.Fragment>);
}

export default AppRoutes;