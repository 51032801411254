import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import storage from '../../helpers/storage';
import './styles.scss';

const PageNoAccessComponent: React.FC<{isPublic?: boolean}> = ({ isPublic }) => {
    const navigate = useNavigate();

    useEffect(() => {
        if (!isPublic && storage.getToken() === null) {
            return navigate('/login', { replace: true });
        }
    }, [navigate, isPublic]);

    return (
        <div className="page-not-found">
            <div className="content">
                <div className='text-center text-dark-emphasis'>
                    <svg fill="none" height="100px" viewBox="0 0 15 15" width="82px" xmlns="http://www.w3.org/2000/svg"><path clip-rule="evenodd" style={{fill:'#495057'}} d="M0 7.5C0 3.35786 3.35786 0 7.5 0C11.6421 0 15 3.35786 15 7.5C15 11.6421 11.6421 15 7.5 15C3.35786 15 0 11.6421 0 7.5ZM2.56379 3.27089C1.58895 4.40766 1 5.88505 1 7.5C1 11.0899 3.91015 14 7.5 14C9.11495 14 10.5923 13.411 11.7291 12.4362L2.56379 3.27089ZM3.27089 2.56379L12.4362 11.7291C13.411 10.5923 14 9.11495 14 7.5C14 3.91015 11.0899 1 7.5 1C5.88505 1 4.40766 1.58895 3.27089 2.56379Z" fill="black" fill-rule="evenodd" /></svg>
                    <h1 className='m-0 mb-1'>Access Denied</h1>
                    <p className='m-0 mb-1'>The page you're looking for, you do not have access to.</p>
                    <p className='m-0'>Please contact Admin.</p>
                </div>
            </div>
        </div>);
}

export default PageNoAccessComponent;
