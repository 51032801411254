import React, { FC } from 'react';
import Form from 'react-bootstrap/Form';

interface IInputText {
    controlId: string;
    label?: string;
    placeholder: string;
    handleChange: any;
    handleBlur: any;
    errorsField: any;
    touched: any
    value: any
    disabled?: any
    setFieldValue?: any;
    onChangeCallback?: Function;
}


/**
 * Input Type Text Reusable Component
 *
 * @param {{ controlId: any; label: any; placeholder: any; handleChange: any; handleBlur: any; errorsField: any; touched: any; }} {
    controlId, label, placeholder, handleChange, handleBlur, errorsField, touched
}
 * @returns {*}
 */
const InputText: FC<IInputText> = ({
    controlId, label, placeholder, handleChange, handleBlur, errorsField, touched, value, disabled, setFieldValue, onChangeCallback
}) => {

    /**
     * Custom blur to trim the value from start & end
     */
    const handleCustomBlur: React.FocusEventHandler = (e: React.FocusEvent<HTMLInputElement>) => {
        const trimmedValue = e.target.value.trim();
        setFieldValue(controlId, trimmedValue);
        handleBlur(e);
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (onChangeCallback) {
            if(e.which === 13 && e.keyCode === 13 && value.trim()) {
                e.preventDefault();
                onChangeCallback(value.trim());
            }
        }
    };
    

    return (
        <Form.Group className="mb-3 pb-1" controlId={controlId}>
            {label ? <Form.Label className="mb-1 fs-14">{label}</Form.Label> : ""}
            <div className="position-relative">
                <Form.Control
                    value={value}
                    type="text"
                    placeholder={placeholder}
                    onBlur={handleCustomBlur}
                    onChange={handleChange}
                    isInvalid={!!errorsField && touched}
                    disabled={disabled} 
                    onKeyDown={handleKeyDown}
                    />
                <Form.Control.Feedback type="invalid">
                    {errorsField}
                </Form.Control.Feedback>
            </div>
        </Form.Group>
    );
}

export default InputText